(function ($) {
    $(function () {
        var modal = $('.alpixel_modal_examination_validate_modal, .alpixel_modal_examination_details_modal, .alpixel_modal_examination_validate_acti_modal');
        modal.on('modal:updated', function (e) {
			var myModal = this;

            $('#trigger-send', myModal).on('click', function (e) {
                e.preventDefault();
                $('div#dropzone-file', myModal).trigger('click');
            });

            var mediaDropzone = new Dropzone($("div#dropzone-file", myModal).get(0), {
                url: basePath + '/media/upload',
                maxFiles: 5,
                parallelUploads: 1,
                maxFilesize: 10,
                acceptedFiles: '.pdf',
            });

            mediaDropzone
                .on("success", function (file, response) {
                    $('ul.file-list', myModal).append('<li>' + response[0]['name'] + ' <button class="btn btn-xs btn-danger"><i class="fa fa-minus-circle"></i></button></li>');

                    var collectionHolder = $('.fileAddPlugin', myModal);
                    var prototype = collectionHolder.attr('data-prototype');
                    var newForm = prototype.replace(/__name__/g, collectionHolder.children().length);
                    var $newFormLi = $('<div></div>').append(newForm);
                    collectionHolder.append($newFormLi);
                    $newFormLi.find('.form-control').val(response[0]['id']);
                });

			$('#examination_acti_validate_state', myModal).on('change', function (e) {
				const $examenSelectedStatus = $('option:selected', e.target);
				if ($examenSelectedStatus.val() == $('fieldset.noteAnnulation', myModal).data('only-visible-when')) {
					$('fieldset.noteAnnulation', myModal).removeClass('hidden');
				}
				else {
					$('fieldset.noteAnnulation textarea', myModal).val('');
					$('fieldset.noteAnnulation', myModal).addClass('hidden');
				}
			});
        });

        $(document).on('click', 'ul.file-list li button.btn-danger', function (e) {
            e.preventDefault();
            var id = $(this).parent().attr('data-id');
            if (id != undefined) {
                $('.filesCollection').find('input[value="' + id + '"]').remove();
            }
            $(this).parent().remove();
        });
    });
})(jQuery);
