(function($){
    $(function(){

        function appendInput(elementToAppend, motif, i)
        {
            elementToAppend.append(
                $(document.createElement('tr'))
                    .append($(document.createElement('td'))
                        .append(motif.replace(/__name__/g, i))
                    )
                    .append($(document.createElement('td'))
                        .append('<button type="button" class="btn btn-sm btn-danger motif-delete"><i class="fa fa-trash"></i></button>')
                    )
            );

            elementToAppend.on('click', '.motif-delete', function()
            {
                $(this).closest('tr').remove();
            });
        }

        $('.alpixel_form_modal.alpixel_modal_parameter_notetype_motifs').on('click', '.motif-add', function () {
            var elementToAppend, items, motif;

            elementToAppend = $(this).closest('.modal-body').find('tbody#noteMotifs');
            items = elementToAppend.find('tr').length;
            motif = elementToAppend.attr('data-prototype-motif');

            appendInput($('tbody#noteMotifs'), motif, items);
            items++;
        });

        $('.alpixel_form_modal.alpixel_modal_parameter_notetype_motifs').on('click', '.motif-delete', function()
        {
            $(this).closest('tr').remove();
        });

        $('.alpixel_form_modal.alpixel_modal_parameter_notetype_motifs').on('click', 'button.submit', function () {
			if ($(this).closest('.modal-body').find('tbody#noteMotifs').find('tr').length == 0) {
				// Workaround pour le cas où on veut supprimer tous les motifs, le formulaire n'est pas valide s'il n'y a plus d'input (pb Symfony Form ?),
				// on ajoute donc un champ vide
				$('.alpixel_form_modal.alpixel_modal_parameter_notetype_motifs .motif-add').trigger('click');
			}
            $(this).closest('.modal-body').find('tbody#noteMotifs').closest('form').submit();
        });
    });
})(jQuery);
