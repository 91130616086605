(function ($) {

    var currentBill;
    $(function () {

        $('table.billingExaminationTable.dataTable').DataTable({
            responsive: true,
            pageLength: 50,
            lengthChange: false,
            dom: 'lrtip',
            columnDefs: [
                { orderData: [0], targets: 1 },
            ],
            order: [[ 1, 'asc' ]],
        });

        $('table.billingDeviceExportTable.dataTable').DataTable({
            responsive: true,
            pageLength: 25,
            lengthChange: false,
            dom: 'lrtip',
            order: [[ 0, 'asc' ]],
        });

        $('table.billingExaminationExportTable.dataTable').DataTable({
            responsive: true,
            pageLength: 25,
            lengthChange: false,
            dom: 'lrtip',
            order: [[ 0, 'desc' ]],
            columnDefs: [
                { orderData: [0], targets: 1 },
                { orderable: false, "targets": [6,7,8,9] },
            ],
        });

        $('table.billingArchiveTable.dataTable').DataTable({
            responsive: true,
            pageLength: 25,
            lengthChange: false,
            dom: 'lrtip',
            order: [[ 0, 'asc' ]],
        });

        $('table.billingExaminationArchiveTable.dataTable').DataTable({
            responsive: true,
            pageLength: 25,
            lengthChange: false,
            dom: 'lrtip',
            order: [[ 0, 'desc' ]],
            columnDefs: [
                { orderData: [0], targets: 1 },
                { orderData: [3], targets: 4 },
            ],
        });

       /* $('table.billingDeviceTable.dataTable').DataTable({
            responsive: true,
            pageLength: 50,
            bPaginate: false,
            lengthChange: false,
            dom: 'lrtip',
            order: [[ 0, 'asc' ]],
            info:     false
        });*/

        $('table.billingDeviceTableOld.dataTable').DataTable({
            responsive: true,
            pageLength: 50,
            lengthChange: false,
            dom: 'lrtip',
            order: [[ 0, 'asc' ]],
            info:     true
        });



        $('table.billingExportHistoryTable.dataTable').DataTable({
            responsive: true,
            pageLength: 25,
            lengthChange: false,
            dom: 'lrtip',
            order: [[ 0, 'desc' ]],
            info : true,
            language: {
                info: "De _START_ à _END_ pour _TOTAL_ exports"
            },
        });

        $('#checkToogle').on('click', function (e) {
            e.preventDefault();
            if ($('.tab-pane').length) {
                // On est sur une vue où la grille est présente dans un onglet
                if ($(' .tab-pane.active table input[type=\'checkbox\']').eq(0).is(':checked')) {
                    $(' .tab-pane.active table').DataTable().rows().every(function ( rowIdx, tableLoop, rowLoop ) {
                        var node = this.node();
                        $(node).find("input[type='checkbox']").prop('checked', false);
                    });
                } else {
                    $(' .tab-pane.active table').DataTable().rows().every(function ( rowIdx, tableLoop, rowLoop ) {
                        var node = this.node();
                        $(node).find("input[type='checkbox']").prop('checked', true);
                    });
                }
            }
            else {
                // On est sur une vue où la grille est présente directement (sans onglet tab)
                if ($('table.billingTable :checkbox').eq(0).is(':checked')) {
                    $('table.billingTable :checkbox').prop('checked', false);
                }
                else {
                    $('table.billingTable :checkbox').prop('checked', 'checked');
                }
            }
        });

        //Recherche manuel sur le datatable depuis un filtre custom
        $('.quickSearch').keyup(function () {
            var dataTable = $('table.billingTable.dataTable').DataTable();
            dataTable.search($(this).val()).draw();
        });

        $('.change-period').on('click', function (e) {
            e.preventDefault();

            $.get(basePath + '/billing/period', function (modalContent) {
                var modal = $(modalContent).appendTo("body");
                modal.modal('show');
                modal.find('.datepicker').datepicker(datepickerOptions);

                $('.btn-period-generator').on('click', function (e) {
                    e.preventDefault();
                    $.get($(this).attr('href'), function (data) {
                        $('#cenas_billing_period_billing_period_start').val(data[0]);
                        $('#cenas_billing_period_billing_period_end').val(data[1]);
                    });
                });

            });
        });

        $('.change-session').on('click', function (e) {
            e.preventDefault();

            $.get(basePath + '/billing/session', function (modalContent) {
                var modal = $(modalContent).appendTo("body");
                modal.modal('show');
                modal.find('.datepicker').datepicker(datepickerOptions);
            });
        });

        $('#validateBill').on('click', function (e) {
            e.preventDefault();
            var bills = $('.massValidate:checked');
            currentBill = 0;
            if (bills.length > 0) {
                $('#billingMassValidateModal .progress-description .total').text(bills.length);
                $('#billingMassValidateModal').modal('show');
                validateBill(bills, currentBill);
            } else {
                alert('Vous n\'avez sélectionné aucune facture');
            }
        });

        $('#deleteValidatedBill').on('click', function (e) {
            e.preventDefault();
            var bills = $('.massValidate:checked');
            deleteBills(bills);
        });

        $('.cancelBill').on('click', function (e) {
            var btn = $(this);
            var billId = btn.attr('data-id');
            $.post(basePath + '/billing/cancel/'+billId, function (data) {
                if (data == "cancel") {
                    document.location.reload();
                }
            });
        });

        $('.massValidate').on('change', function (e) {
            if ($(this).prop('checked') == true) {
                $(this).parentsUntil('tr').parent().addClass('active');
            } else {
                $(this).parentsUntil('tr').parent().removeClass('active');
            }
        });

        $('.downloadExport').on('click', function (e) {

            var btn           = $(this);
            var exportId     = btn.attr('data-export');

            var attr = {};
            $.post(basePath+'/billing/export/download/'+exportId, attr, function (data) {
                btn.removeClass('btn-info').addClass('btn-success');
                btn.parent().parent().css( "background-color", "#a5decd" );

                $('table i.fa.fa-spinner').hide();
                btn.find('i').show();
            });
        });

    });

    function validateBill(bills, nb) {
        nb++;

        var percent = (nb / bills.length * 100);
        var bill = $(bills[(nb - 1)]);
        var attr = {};

        if (bill.attr('data-date') != undefined) {
            attr['date'] = bill.attr('data-date');
        }

        $('#billingMassValidateModal .progress-description .current').text(nb);
        $('#billingMassValidateModal .progress-bar').css('width', percent + '%');

		var urlBilling;
		if (bill.attr('data-type') == 'device') {
			urlBilling = basePath + '/billing/create/manual/device/' + bill.attr('data-id');
		}
		else {
			urlBilling = basePath + '/billing/create/manual/' + bill.attr('data-type') + '/' + bill.attr('data-patient');
		}
        $.post(urlBilling, attr, function (data) {
            if (nb < bills.length)
                validateBill(bills, nb);
            else {
                $('#billingMassValidateModal').find('.progress, .progress-description').hide();
                $('#billingMassValidateModal').find('.info-box').show();
                $('.massValidate').prop('checked', false);
                setTimeout(function () {
                    document.location.reload();
                }, 750);
            }
        });
    }


    function deleteBills(bills) {
        if (bills.length === 0) {
            alert('Vous n\'avez sélectionné aucune facture');
        } else {
            $.confirm({
                confirm: function (button) {
                    var ids = [];
                    $(bills).each(function (i, el) {
                        ids.push($(el).val());
                    });
                    $.post(basePath + '/billing/delete', {bills: ids}, function (data) {
                        if (data == "deleted") {
                            document.location.reload();
                        }
                    });
                }
            });
        }
    }
})(jQuery);
