(function ($) {
	$(function () {

		function updateSelectNoteMotif($select) {
			if ($select.children('option').length > 1) {
				$select.parent().show();
				$select.prop('required', true);
			}
			else {
				$select.parent().hide();
				$select.prop('required', false);
			}
		}

		function initializeNoteForm(dom) {
			var $form = $('form[name="note"]', dom);

			if ($form.length) {
				var $selectNoteType = $form.find('select#note_type');
				var $selectNoteMotif = $form.find('select#note_motif');

				$selectNoteType.change(function() {

					$.ajax({
						type: 'POST',
						dataType: "json",
						url: $form.data('url-get-motifs'),
						data: $form.serialize()
					}).done(function (data) {
						$selectNoteMotif.html($(data.html).find('form[name="note"] select#note_motif').html());

						updateSelectNoteMotif($selectNoteMotif);
					});

				});

				updateSelectNoteMotif($selectNoteMotif);
			}
		}

		initializeNoteForm(document);

		$(document).on('modal:updated', function (e) {
			initializeNoteForm(e.target);
		});

	});
})(jQuery);
